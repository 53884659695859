import React from 'react'
import { Badge } from 'reactstrap';
import ReactImageFallback from "react-image-fallback";

class TagBadge extends React.Component {
  render() {
    const { tag } = this.props

    const values = {
      'breidablik': '#26705a',
      'facebook': '#4267b2',
      'strava': '#fc4c02',
      'garmin': '#808080',
      'trainingpeaks': '#000',
    }

    const color = values[tag] || '#A9A9A9';
  

    return (
      <strong><Badge href={`/tags/${this.props.tag}`} style={{backgroundColor: color}}>
        <ReactImageFallback
     
                    src={`../../images/${tag}.png`}
                    fallbackImage="../../images/tag.png"
                    initialImage="loader.gif"
                    alt=""
                    className="my-image" />  {tag}
        </Badge>
        </strong>
    )
  }
}

export default TagBadge
