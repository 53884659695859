import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components';

import Layout from '../components/layout'
import PostList from '../components/PostList';
import SEO from '../components/seo'
import media from '../utils/media';

import TagBadge from '../components/TagBadge';

const Container = styled.div`
  padding: 1rem 0;
  margin: 1rem 0;

  &:first-child {
    margin-top: 0;
  }

  ${media.phone`
    margin: 0.5rem 0;
    padding: 0.4rem 0;
  `}
`;

class Tags extends React.Component {
  render() {
    const pageTitle = `#${this.props.pageContext.tag}`
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location} title={pageTitle}>
        <SEO title={pageTitle} />
        <title>{pageTitle}</title>
   

        <Container>
          <h1>Færslur merktar með <TagBadge tag={this.props.pageContext.tag} /></h1>
          
          <PostList posts={posts} />
        </Container>
      </Layout>
    )
  }
}

export default Tags

export const pageQuery = graphql`
  query PostsByTag($tag: String!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            language
            slug
          }
        }
      }
    }
  }
`
