import React, { Fragment } from 'react'
import { Link } from "gatsby";
import styled from 'styled-components';
import DraftBagdge from './DraftBadge';

const LI = styled.li`
padding: 5px 0;
font-size: 1.1em;
line-height: 1.4;
`;

const UL = styled.ul`
margin: 0px 0px 20px;
padding: 20px;
list-style: circle;
`;



class PostsList extends React.Component {
  render() {
    const { posts } = this.props

    return (
      <Fragment>
        <UL>      
        {posts.map((post,i) => {
          const props = {
            title: post.node.frontmatter.title,
            slug: post.node.frontmatter.slug,
            date: post.node.frontmatter.date,
            language: post.node.frontmatter.language || 'is',
            tags: post.node.frontmatter.tags || [],
          } 
          return <LI key={i}><Link to={props.slug}>{props.title}</Link><DraftBagdge tags={props.tags} /></LI>
        })}
      


        </UL>
      </Fragment>
    )
  }
}
export default PostsList