import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import ScrollButton from './ScrollButton';
import media from '../utils/media';
import 'bootstrap/dist/css/bootstrap.min.css';
require(`katex/dist/katex.min.css`);

const Footer = styled.footer`
  display: block;
  height: 6rem;
  text-align: center;
  padding: 20px;
`;

const Content = styled.div`
  width: 60%;
  max-width: 728px;
  margin: 0px auto;
  background-color: white;
  border-top 10px solid #f8f9fa;
  border-bottom 10px solid #f8f9fa;
  padding: 1em;

  ${media.tablet`
    width: 80%;
  `}
`;

const ContentWrapper = styled.div`
  width: 100%;
  background-color: #f8f9fa;
`;

const A = styled.a`
  color: #696969;
`;

class Layout extends Component {
  render() {
    const { children } = this.props;
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <>
            <Header title={data.site.siteMetadata.title} />
            <ContentWrapper>
              <Content>{children}</Content>
            </ContentWrapper>
            <Footer>
              <p>
                Ábendingar varðandi síðu sendist á:{' '}
                <A href="mailto:joningis@gmail.com">joningis@gmail.com</A>.
              </p>
            </Footer>
            <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
          </>
        )}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
