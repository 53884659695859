import React, { Fragment } from 'react'
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

class DraftBadge extends React.Component {
  render() {
    const { tags } = this.props

    const draft = tags.includes('draft');

    if (!draft) {
      return null;
    }

    return (
      <Fragment>
        &nbsp;<Badge color="danger">draft</Badge>
      </Fragment>
    )
  }
}

DraftBadge.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default DraftBadge
